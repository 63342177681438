import React, { Component } from 'react';
import UsersTable from '../components/UsersTable/UsersTable';
import withAuthorization from '../components/withAuthorization';
import { db, auth, getNeighbourhoodId } from '../firebase/firebase';
import { showSnackbar, showSnackbarWithType, showSnackbarWithTimeout } from '../components/Snackbar.js';
import { withUsers } from '../context/UsersContext';
import firebase from 'firebase/app';
import 'firebase/functions';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import PasswordUtils from '../utils/PasswordUtils';

const phoneUtil = PhoneNumberUtil.getInstance();

class Teamleden extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      roles: [],
      modalEditUserIsOpen: false,
      modalEditUserPhoneNumberIsOpen: false,
      modalEditUserRolesIsOpen: false,
      modalDeleteUserIsOpen: false,
      modalEditUserNotifiersIsOpen: false,
      lastSelectedUser: '',
      newUserRoles: [],
      newUserNotifiers: {},
      inputPincode: '',
      user: null
    };

    this.functions = {
      deleteUser: this.deleteUser,
      openEditUserModal: this.openEditUserModal,
      closeEditUserModal: this.closeEditUserModal,
      openEditUserPhoneNumberModal: this.openEditUserPhoneNumberModal,
      closeEditUserPhoneNumberModal: this.closeEditUserPhoneNumberModal,
      handleEditUserSaveClicked: this.handleEditUserSaveClicked,
      handleEditUserPhoneNumberSaveClicked: this.handleEditUserPhoneNumberSaveClicked,
      openDeleteUserModal: this.openDeleteUserModal,
      closeDeleteUserModal: this.closeDeleteUserModal,
      handleDeleteUserSaveClicked: this.handleDeleteUserSaveClicked,
      updateInputPincode: this.updateInputPincode,
      updatePin: this.updatePin,
      updateInputPhoneNumber: this.updateInputPhoneNumber,
      updatePhoneNumber: this.updatePhoneNumber,
      updateUserRoles: this.updateUserRoles,
      onChangeRoles: this.onChangeRoles,
      openEditUserRolesModal: this.openEditUserRolesModal,
      closeEditUserRolesModal: this.closeEditUserRolesModal,
      handleEditUserRolesSaveClicked: this.handleEditUserRolesSaveClicked,
      openEditUserNotifiersModal: this.openEditUserNotifiersModal,
      closeEditUserNotifiersModal: this.closeEditUserNotifiersModal,
      handleEditUserNotifiersSaveClicked: this.handleEditUserNotifiersSaveClicked,
      onChangeNotifiers: this.onChangeNotifiers
    }
  }

  componentDidMount() {
    this.props.usersContextActions.register(this);

    showSnackbarWithTimeout('Gegevens laden..', 1300);
    this.onRolesSnapshotUnsubscriber = db.collection('roles').onSnapshot(this.getAllRoles);

    auth.onAuthStateChanged(user => {
      this.setState({ user });
    });

    setTimeout(() => {
      this.setState({ users: this.props.users });
    }, 1000);

  }

  componentWillUnmount() {
    this.props.usersContextActions.unregister(this);
    this.onRolesSnapshotUnsubscriber();
  }

  getAllRoles = (querySnapshot) => {
    let roles = [];
    querySnapshot.forEach(doc => {
      const data = doc.data();
      roles.push({
        key: doc.id,
        id: doc.id,
        ...data
      })
    });
    console.log("roles: ", roles);
    this.setState({ roles });
  }

  getRoleAlias = id => {
    const role = this.state.roles.find(r => r.id === id);
    return role ? role.alias : '.';
  }

  openEditUserModal = (user) => {
    this.setState({ modalEditUserIsOpen: true, lastSelectedUser: user });
  }

  closeEditUserModal = () => {
    this.setState({ modalEditUserIsOpen: false });
  }

  openEditUserPhoneNumberModal = (user) => {
    this.setState({ modalEditUserPhoneNumberIsOpen: true, lastSelectedUser: user });
  }

  closeEditUserPhoneNumberModal = () => {
    this.setState({ modalEditUserPhoneNumberIsOpen: false });
  }

  handleEditUserSaveClicked = (e) => {
    this.updatePin();
  }

  handleEditUserPhoneNumberSaveClicked = (e) => {
    this.updatePhoneNumber();
  }

  openDeleteUserModal = (user) => {
    this.setState({ modalDeleteUserIsOpen: true, lastSelectedUser: user });
  }

  closeDeleteUserModal = () => {
    this.setState({ modalDeleteUserIsOpen: false });
  }

  handleDeleteUserSaveClicked = async () => {
    showSnackbar('Gebruiker proberen te verwijderen...');
    this.closeDeleteUserModal();
    await this.deleteUser(this.state.lastSelectedUser);
  }

  deleteUser = async (user) => {
    const neighbourhoodId = getNeighbourhoodId();
    const deleteUser = firebase.functions().httpsCallable('deleteUser');
    return deleteUser({ uid: user.uid, neighbourhoodId })
      .then(() => {
        showSnackbar('Gebruiker wordt verwijderd.');
      })
      .catch((error) => {
        console.error('Error removing document: ', error);
        showSnackbarWithType('Er is iets fout gegaan', 'error');
      });
  }

  updateInputPincode = (evt) => {
    this.setState({
      inputPincode: evt.target.value
    });
  }

  updatePin = () => {
    const user = this.state.lastSelectedUser;
    const newPassword = this.state.inputPincode;

    if (!PasswordUtils.isPasswordValid(newPassword)) {
      showSnackbarWithType('Wachtwoord voldoet niet aan de eisen.', 'error');
      return;
    }

    showSnackbar('Wachtwoord proberen te updaten...');

    const neighbourhoodId = getNeighbourhoodId();
    const updateUser = firebase.functions().httpsCallable('updateUser');
    updateUser({
      uid: user.key,
      password: newPassword,
      neighbourhoodId
    }).then(() => {
      this.setState({ modalEditUserIsOpen: false });
      showSnackbar('Het wachtwoord is aangepast');
    }).catch((error) => {
      console.error('Error creating user: ', error);
      showSnackbarWithType('Er is iets fout gegaan, probeer het nog een keer.', 'error');
    });
  }

  updateInputPhoneNumber = (evt) => {
    this.setState({
      inputPhoneNumber: evt.target.value
    });
  }

  updatePhoneNumber = () => {
    const user = this.state.lastSelectedUser;
    const newPhoneNumber = this.state.inputPhoneNumber;
    if (!newPhoneNumber) {
      showSnackbarWithType('vul een telefoonnummer in.', 'error');
      return;
    }

    let number = phoneUtil.parseAndKeepRawInput(newPhoneNumber, 'NL');
    if (!phoneUtil.isValidNumber(number)) {
      showSnackbarWithType('Vul een geldig telefoonnummer in.', 'error');
      return;
    }

    const e164PhoneNumber = phoneUtil.format(number, PhoneNumberFormat.E164);

    showSnackbar('Telefoonnummer proberen te updaten...');

    const neighbourhoodId = getNeighbourhoodId();
    const updateUser = firebase.functions().httpsCallable('updateUserPhoneNumber');
    updateUser({
      uid: user.key,
      phoneNumber: e164PhoneNumber,
      neighbourhoodId
    }).then(() => {
      this.setState({ modalEditUserPhoneNumberIsOpen: false });
      showSnackbar('Het telefoonnummer is aangepast');
    }).catch((error) => {
      console.error('Error updating user phone number: ', error);
      showSnackbarWithType('Er is iets fout gegaan, probeer het nog een keer.', 'error');
    });
  }

  updateUserRoles = async (user, rolesArray) => {
    showSnackbar('Bezig met rol aanpassen...');

    if (!rolesArray.includes('user')) {
      rolesArray.push('user');
    }

    try {
      await db
        .neighbourhoodCollection('users')
        .doc(user.key)
        .update({
          roles: rolesArray
        });

      await db
        .collection('users')
        .doc(user.key)
        .update({
          roles: rolesArray
        });

      firebase.functions().httpsCallable('logAuditTrail')({
        event: 'user-roles-updated',
        uid: user.key || 'unkown',
        initiator: this.state.user.uid,
        message: `user-roles-updated ${user.key} to ${rolesArray.join(', ')} by ${this.state.user.uid}`,
      });

      showSnackbar('De rollen zijn aangepast');
    } catch (error) {
      console.error(error);
      showSnackbarWithType('Er is iets fout gegaan', 'error');
    }
  }

  onChangeRoles = roles => {
    this.setState({ newUserRoles: roles });
  }

  openEditUserRolesModal = user => {
    this.setState({ modalEditUserRolesIsOpen: true, lastSelectedUser: user, newUserRoles: user.roles });
  }

  closeEditUserRolesModal = () => {
    this.setState({ modalEditUserRolesIsOpen: false });
  }

  handleEditUserRolesSaveClicked = () => {
    this.updateUserRoles(this.state.lastSelectedUser, this.state.newUserRoles);
    this.setState({ modalEditUserRolesIsOpen: false });
  }

  openEditUserNotifiersModal = user => {
    this.setState({ modalEditUserNotifiersIsOpen: true, lastSelectedUser: user, newUserNotifiers: user.allowNotifications });
  }

  closeEditUserNotifiersModal = () => {
    this.setState({ modalEditUserNotifiersIsOpen: false });
  }

  handleEditUserNotifiersSaveClicked = () => {
    this.updateNotificationSettings(this.state.lastSelectedUser, this.state.newUserNotifiers);
    this.setState({ modalEditUserNotifiersIsOpen: false });
  }

  getNotificationSettings = (user) => {
    return {
      sms: user.allowNotifications && user.allowNotifications.sms !== undefined ? user.allowNotifications.sms : false, // defaults to false
      push: user.allowNotifications && user.allowNotifications.push !== undefined ? user.allowNotifications.push : true, // defaults to true  
    };
  }

  updateNotificationSettings = async (user, notificationSettings) => {
    showSnackbar('Bezig met notificaties aanpassen...');
    try {
      await db
        .neighbourhoodCollection('users')
        .doc(user.key)
        .update({
          allowNotifications: notificationSettings
        });

      await db
        .collection('users')
        .doc(user.key)
        .update({
          allowNotifications: notificationSettings
        });
      showSnackbar('De notificaties zijn aangepast');
    } catch (error) {
      console.error(error);
      showSnackbarWithType('Er is iets fout gegaan', 'error');
    }
  }

  onChangeNotifiers = notifiers => {
    this.setState({ newUserNotifiers: notifiers });
  }

  render() {
    return (
      <div className="container-fluid content">
        <div>
          <a href="/register" className="btn btn-primary btn-raised">Nieuw teamlid toevoegen</a>
        </div>

        <UsersTable
          users={this.props.users}
          currentUser={this.state.user}
          lastSelectedUser={this.state.lastSelectedUser}
          roles={this.state.roles}
          newUserRoles={this.state.newUserRoles}
          getRoleAlias={this.getRoleAlias}
          modalEditUserIsOpen={this.state.modalEditUserIsOpen}
          modalEditUserPhoneNumberIsOpen={this.state.modalEditUserPhoneNumberIsOpen}
          modalEditUserRolesIsOpen={this.state.modalEditUserRolesIsOpen}
          modalDeleteUserIsOpen={this.state.modalDeleteUserIsOpen}
          modalEditUserNotifiersIsOpen={this.state.modalEditUserNotifiersIsOpen}
          newUserNotifiers={this.state.newUserNotifiers}
          functions={this.functions}
        />

      </div>
    );
  }
}

const authCondition = (authUser) => !!authUser;

export default withUsers(withAuthorization(authCondition)(Teamleden));
